import { setUser } from '~/../common/utils/user'

const state = () => ({
  userExists: false,
  userExistsError: {},
  step: 1,
  email: '',
  modalOpen: false,
  qes: false,
  qesZip: null,
  loading: false,
  thankYou: false,
  modalTheme: {},
  loginRedirect: null,
  loginRedirectReloadPage: false,
  settingsVars: {},
})

const mutations = {
  CHECK_USER_EXISTS(state, exists) {
    state.userExists = exists
  },
  SET_STEP(state, step) {
    state.step = step
  },
  SET_EMAIL(state, email) {
    state.email = email
  },
  SET_QES_ZIP(state, zip) {
    state.qesZip = zip
  },
  SET_USER_EXISTS_ERROR(state, error) {
    state.userExistsError = error
  },
  SET_USER_EXISTS(state, boolean) {
    state.userExists = boolean
  },
  SET_LOGIN_MODAL_THEME(state, theme) {
    state.modalTheme = theme
  },
  SET_LOGIN_REDIRECT(state, loginRedirect) {
    state.loginRedirect = loginRedirect
  },
  SET_LOGIN_REDIRECT_RELOAD_PAGE(state, loginRedirectReloadPage) {
    state.loginRedirectReloadPage = loginRedirectReloadPage
  },
  SET_LOGIN_IS_CLUB(state, loginIsClub) {
    state.loginIsClub = loginIsClub
  },
  TOGGLE_LOGIN_MODAL(state) {
    state.modalOpen = !state.modalOpen
    if (!state.modalOpen) {
      state.loginRedirect = null
      state.loginRedirectReloadPage = false
      state.step = 1
      state.email = ''
      state.userExists = false
    }
  },
  SET_LOGIN_MODAL_OPEN(state, open) {
    state.modalOpen = open
    if (!state.modalOpen) {
      state.loginRedirect = null
      state.loginRedirectReloadPage = false
      state.step = 1
      state.email = ''
      state.userExists = false
    }
  },
  TOGGLE_QES(state) {
    state.qes = !state.qes
  },
  TOGGLE_LOADING(state) {
    state.loading = !state.loading
  },
}

const actions = {
  toggleLoginModal({ dispatch, state }, data) {
    if (!data) {
      data = {}
    }
    if (data.open === undefined) {
      data.open = !state.modalOpen
    }
    dispatch('setLoginModalOpen', data)
  },
  setLoginModalOpen({ commit }, data) {
    if (!data) {
      data = {}
    }

    if (data.modalTheme) {
      commit('SET_LOGIN_MODAL_THEME', data.modalTheme)
    } else {
      commit('SET_LOGIN_MODAL_THEME', {})
    }

    if (data.loginRedirect) {
      commit('SET_LOGIN_REDIRECT', data.loginRedirect)
    } else {
      commit('SET_LOGIN_REDIRECT', null)
    }

    if (data.loginRedirectReloadPage) {
      commit('SET_LOGIN_REDIRECT_RELOAD_PAGE', data.loginRedirectReloadPage)
    } else {
      commit('SET_LOGIN_REDIRECT_RELOAD_PAGE', false)
    }

    if (data.isClub) {
      commit('SET_LOGIN_IS_CLUB', data.isClub)
    } else {
      commit('SET_LOGIN_IS_CLUB', false)
    }

    commit('SET_LOGIN_MODAL_OPEN', data.open)
  },
  toggleQes({ commit }) {
    commit('TOGGLE_QES')
  },
  setEmail({ commit }, email) {
    commit('SET_EMAIL', email)
  },
  setStep({ commit }, step) {
    commit('SET_STEP', step)
  },
  setUserExists({ commit }, boolean) {
    commit('SET_USER_EXISTS', boolean)
  },
  checkUserExists({ commit }, email) {
    this.$axios
      .post('/api/customer/check_email/', {
        email,
      })
      .then((resp) => {
        commit('CHECK_USER_EXISTS', resp.data.user)
        commit('SET_QES_ZIP', resp.data.zip_code || null)
        commit('SET_STEP', 2)
        commit('SET_EMAIL', email)
        commit('SET_USER_EXISTS_ERROR', {})
      })
      .catch((e) => {
        commit('SET_USER_EXISTS_ERROR', e.response && e.response.data)
      })
  },
  async registerAccount({ commit, state }, data) {
    commit('TOGGLE_LOADING')
    try {
      const resp = await this.$axios.post('/api/customer/register/', {
        email: state.email,
        first_name: data.first_name,
        last_name: data.last_name,
        default_zipcode: data.default_zipcode,
        date_of_birth: data.date_of_birth,
        password: data.password,
        is_club: state.loginIsClub,
        recaptcha: data.recaptcha,
      })

      setUser(this.$auth, resp.data.user, resp.data.token, {
        registeredFromLoginModal: !!data.registeredFromLoginModal,
      })

      commit('TOGGLE_LOGIN_MODAL')
      commit('SET_USER_EXISTS', true)
    } catch (e) {
      throw e.response.data
    } finally {
      commit('TOGGLE_LOADING')
    }
  },
  async registerQes({ commit, state }, data) {
    commit('TOGGLE_LOADING')
    await this.$axios.post(`/api/customer/qes/`, {
      email: data.qesEmail,
      zip: data.zipcode,
      daily_email_active: true,
    })
    state.thankYou = true
    setTimeout(() => {
      commit('TOGGLE_QES')
      state.thankYou = false
      commit('TOGGLE_LOADING')
    }, 8000)
  },
}

const getters = {
  email(state) {
    return state.email
  },
  userExistsError(state) {
    return state.userExistsError
  },
  userExists(state) {
    return state.userExists
  },
  step(state) {
    return state.step
  },
  modalOpen(state) {
    return state.modalOpen
  },
  modalTheme(state) {
    return state.modalTheme
  },
  loginRedirect(state) {
    return state.loginRedirect
  },
  loginRedirectReloadPage(state) {
    return state.loginRedirectReloadPage
  },
  qes(state) {
    return state.qes
  },
  qesZip(state) {
    return state.qesZip
  },
  loading(state) {
    return state.loading
  },
  thankYou(state) {
    return state.thankYou
  },
}

export default {
  state,
  mutations,
  actions,
  getters,
}
